
import ReviewContents from './ReviewContents.vue';
import ReviewHeader from './ReviewHeader.vue';
import InvisibleReview from './InvisibleReview.vue';

export default {
  name: 'Review',
  components: {
    InvisibleReview,
    ReviewContents,
    ReviewHeader,
  },
  props: {
    id: { type: Number, required: true },
    user: { type: Object, required: true },
    userChildrenAge: { type: Number, required: true },
    createdAt: { type: String, required: true },
    deletedAt: { type: String, default: '' },
    rating: { type: Number, required: true },
    images: { type: Array, default: () => [] },
    comment: { type: String, default: '' },
    productTitle: { type: String, default: '' },
    reportStatus: { type: String, default: 'NONE' },
    isBlind: { type: Boolean, default: false },
    deletedBy: { type: String, default: '' },
    index: { type: Number, required: true },
  },
};
