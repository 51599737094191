import { render, staticRenderFns } from "./ReviewHeader.vue?vue&type=template&id=d84d73a4&scoped=true"
import script from "./ReviewHeader.vue?vue&type=script&lang=js"
export * from "./ReviewHeader.vue?vue&type=script&lang=js"
import style0 from "./ReviewHeader.vue?vue&type=style&index=0&id=d84d73a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d84d73a4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewAvatar: require('/codebuild/output/src2421731460/src/components/review/ReviewAvatar.vue').default,ReviewUser: require('/codebuild/output/src2421731460/src/components/review/ReviewUser.vue').default,ReviewStars: require('/codebuild/output/src2421731460/src/components/review/ReviewStars.vue').default})
